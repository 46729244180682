import React from 'react';
import { mq, fontWeights, colors, fontSizes } from '../../theme';
import Image from '../Image';

const PointImage = ({ width, source, number, children, gray }) => {
  return (
    <div
      css={mq({
        position: 'relative',
        textAlign: 'center',
        width,
        ':before': number && {
          position: 'absolute',
          content: `"${number}"`,
          top: 0,
          bottom: 0,
          right: 0,
          left: 0,
          fontSize: 180,
          fontWeight: fontWeights.bold,
          lineHeight: '140px',
          textAlign: 'center',
          width: '100%',
          color: '#272B2E'
        }
      })}
    >
      <div
        css={{
          position: 'relative',
          margin: '24px auto 52px',
          backgroundColor: gray ? colors.neutralGray : colors.neutralWhite,
          width: 98,
          height: 98,
          borderRadius: '50%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Image
          src={source}
          css={{
            width: 40
          }}
        />
      </div>
      <div
        css={{
          color: colors.neutralWhite,
          fontSize: fontSizes.semiBig,
          lineHeight: '26px',
          width: '90%',
          height: 150,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'flex-start',
          margin: '0px auto'
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default PointImage;
